<template>
  <v-dialog v-model="showCreateRecordModal" max-width="400px" overlay-opacity="0.3" overlay-color="grey">
    <template v-slot:activator="{ on }">
      <v-btn v-if="!welcomePage" v-on="on" block ripple class=" py-3" height="auto">
        <div class="text-subtitle-2 text-sm-title">
          <v-icon>mdi-plus</v-icon>
          <br />
          <div class="mt-2" style="white-space: break-word">{{ $vuetify.breakpoint.smAndDown ? 'New' : 'New Registration' }}</div>
        </div>
      </v-btn>

     <v-btn v-on="on" v-if="welcomePage" type="button" color="success" class="py-8 px-12 text-h4">

        <v-icon>mdi-plus</v-icon> {{ $vuetify.breakpoint.smAndUp ? 'Create New Registration' : 'Registration' }}
      </v-btn>
      <!-- <v-btn v-on="on" v-if="!welcomePage" type="button" color="success" text>
        {{ smAndUp ? 'Create New Registration' : 'Registration' }} <v-icon class="rounded-circle green white--text ml-2">mdi-plus</v-icon>
      </v-btn> -->
    </template>
    <v-form ref="createRegistrationForm" v-model="createRegistrationFormIsValid">
      <v-card class="">
        <v-card-title class="headline mb-2">Create a New Registration</v-card-title>
        <v-card-subtitle>Enter the Year, Make, and Model of your vehicle</v-card-subtitle>
        <!-- <v-divider class="mx-4"></v-divider> -->
        <v-card-text>
          <!-- <v-select
            required
            single-line
            menu-props="auto"
            v-model="newRecord.year"
            :items="yearChoices"
            :rules="rules.notEmpty"
            :disabled="requestPending"
            ><template v-slot:prepend>
              <div class="pa-2">
                Year
              </div>
            </template></v-select
          >

          <v-select
            required
            single-line
            menu-props="auto"
            v-model="newRecord.make"
            :items="makeChoices"
            :rules="rules.notEmpty"
            :disabled="requestPending"
            ><template v-slot:prepend>
              <div class="pa-2">
                Make
              </div>
            </template></v-select
          > -->

            <v-text-field
            required
            single-line
            counter="4"
            menu-props="auto"
            v-model="newRecord.year"
            :maxlength="4"
            :rules="rules.notEmpty"
            :disabled="requestPending"
            ><template v-slot:prepend>
              <div class="pa-2">
                Year
              </div>
            </template></v-text-field
          >

            <v-text-field
            required
            single-line
            counter="20"
            menu-props="auto"
            v-model="newRecord.make"
            :maxlength="20"
            :rules="rules.notEmpty"
            :disabled="requestPending"
            ><template v-slot:prepend>
              <div class="pa-2">
                Make
              </div>
            </template></v-text-field
          >

          <v-text-field
            required
            single-line
            counter="40"
            menu-props="auto"
            v-model="newRecord.model"
            :maxlength="40"
            :rules="rules.notEmpty"
            :disabled="requestPending"
            ><template v-slot:prepend>
              <div class="pa-2">
                Model
              </div>
            </template></v-text-field
          >
        </v-card-text>
        <!-- <v-divider class="mx-4"></v-divider> -->
        <v-card-actions>
          <v-btn
            outlined
            color="error"
            :disabled="requestPending"
            @click="
              resetTheForm();
              showCreateRecordModal = false;
            "
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            color="success"
            :loading="requestPending"
            :disabled="!createRegistrationFormIsValid"
            @click.native.prevent="createRegistration()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import VueRouter from '../../../../router';

export default {
  components: {},
  props: {
    welcomePage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created() {},
  data: () => ({
    newRecord: {
      year: null,
      make: null,
      model: null,
    },
    rules: {
      notEmpty: [v => !!v || 'Field is required', v => (v && v.length <= 40) || 'Must be less than 50 characters'],
    },
    yearChoices: ['2026','2025','2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
    makeChoices: [
      'Acura',
      'BMW',
      'Chevrolet',
      'Ford',
      'GMC',
      'Infiniti',
      'Lexus',
      'Mercedes',
      'Nissan',
      'Porsche',
      'Tesla',
      'Toyota',
      'Volkswagon',
    ],
    requestPending: false,
    showCreateRecordModal: false,
    createRegistrationFormIsValid: false,
  }),
  computed: {
    smAndUp: function() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  methods: {
    showSuccessSnack(msg) {
      this.$store.commit('snackmsg/show', { text: msg });
    },
    showErrorSnack(msg) {
      this.$store.commit('snackmsg/show', { text: msg, color: 'error' });
    },
    resetTheForm() {
      this.$refs.createRegistrationForm.reset();
    },
    createRegistration() {
      this.requestPending = true;
      const payload = this.newRecord;
      this.$store
        .dispatch('dashboard/createRegistration', payload)
        .then(response => {
          this.resetTheForm();
          this.showSuccessSnack(response.msg);
          // this.$store.dispatch('dashboard/getVehicles');
          VueRouter.push({ name: 'app.registration.application', params: { regId: response.regId } });
        })
        .catch(error => {
          this.resetTheForm();
          this.showErrorSnack(error);
        });
    },
  },
};
</script>

<style scoped>
.error-msg {
  background: #f9c7c7;
  padding: 15px 10px;
  color: brown;
  border: none;
  margin-bottom: 20px;
}
</style>
